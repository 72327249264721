import { ISideBar } from '@/types/types';

export const links: { [key: string]: Array<ISideBar | { label: string }> } = {
  users: [
    {
      label: 'General',
    },
    {
      link: 'account.dashboard',
      icon: 'widgets',
      text: 'Dashboard',
    },
    {
      link: 'account.groups',
      icon: 'group_work',
      text: 'Groups',
    },
    {
      link: 'account.contacts',
      icon: 'dialpad',
      text: 'Contacts',
    },
    {
      link: 'account.sender.ids',
      icon: 'send_to_mobile',
      text: 'Sender IDs',
    },
    {
      label: 'Campaigns',
    },
    {
      link: 'account.sms.quick',
      icon: 'send',
      text: 'Quick SMS',
    },
    {
      link: 'account.sms.bulk',
      icon: 'all_inbox',
      text: 'Bulk SMS',
    },
    {
      link: 'account.sms.personalize',
      icon: 'tune',
      text: 'Personalize SMS',
      isNew: false,
    },
    {
      label: 'Automations',
    },
    {
      link: 'account.events.list',
      icon: 'event',
      text: 'Events',
      isNew: false,
    },
    {
      label: 'Settings',
    },
    {
      link: 'account.profile',
      icon: 'face',
      text: 'My Profile',
      isNew: false,
    },
    {
      link: 'account.api.keys',
      icon: 'api',
      text: 'API Keys',
    },
    {
      link: 'account.sub-accounts',
      icon: 'manage_accounts',
      text: 'Sub Accounts',
      isNew: false,
      isReseller: true,
    },
    { label: 'Insights' },
    // {
    //   link: 'account.messages',
    //   icon: 'insights',
    //   text: 'Overview',
    //   isNew: false,
    // },
    {
      link: 'account.messages',
      icon: 'campaign',
      text: 'Sent Messages',
      isNew: false,
    },
    {
      link: 'account.top-up.history',
      icon: 'receipt_long',
      text: 'Top Up History',
      isNew: true,
    },
    {
      link: 'account.downloads',
      icon: 'file_download',
      text: 'Downloads',
      isNew: true,
    },
    {
      label: 'Help Center',
    },
    {
      link: 'account.feedback',
      icon: 'feedback',
      text: 'Feedback',
    },
    {
      link: 'account.bug.report',
      icon: 'bug_report',
      text: 'Bug Report',
    },
  ],
  doctor: [
    {
      label: 'General',
    },
    {
      link: 'accounts.admin.dashboard',
      icon: 'people',
      text: 'Patients',
    },
  ],
};
